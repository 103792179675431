// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'
import Highlights from 'components/Contentful/Highlights'
import Knowledge from 'components/Contentful/Knowledge'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Einfach und sicher eine Ölheizung mit einer Wärmepumpe ersetzen."
        meta={[
          { name: 'description', content: 'Alles spricht für Wärmepumpen. Wenn es allerdings eine bestehende Heizung zu erneuern gilt, ist die Installation meist etwas aufwändiger als ein Ersatz durch das frühere System.' },
          { name: 'keywords', content: '' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='39cZL8zFReAGye8SKOESue-yxLLx9yk1iQYYsea6Ooak'
          id='Dienstleistung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "yxLLx9yk1iQYYsea6Ooak",
    "updatedAt": "2018-11-30T09:09:56.933Z",
    "title": "Einer für alle",
    "text": "<p>Alles spricht für Wärmepumpen. Wenn es allerdings eine bestehende Heizung zu erneuern gilt, ist die Installation meist etwas aufwändiger als ein Ersatz durch das frühere System: Es braucht neben dem Heizungsinstallateur oft auch einen Elektriker, einen Baumeister und manchmal auch eine Bewilligung. Dies ist keine grosse Sache, aber dennoch muss der Umbau richtig konzipiert und koordiniert werden, damit alles reibungslos klappt.</p>\n<p>Genau darauf haben wir uns spezialisiert: Die Heizungsmacher AG übernimmt die gesamte Planung und Koordination und kümmert sich darum, dass Sie ganz einfach zum besten Wärmepumpensystem für Ihr Haus kommen. Als spezialisierte Fachleute gewährleisten wir eine korrekte Dimensionierung und Installation und vermeiden Fehler, die leider in der Branche immer wieder vorkommen. Ausserdem stellen wir die Anlage fachgerecht ein. Und zu guter Letzt: Auch nach der Inbetriebnahme profitieren Sie von unserer Kompetenz und sind für den Service für Sie da.</p>\n",
    "image": {
        "description": "Heizungsmacher Erklärung Geschäftsmodell Komplettlösung mit lokalen Profis",
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/3QnBSrxoM0aSscYECAI4ce/5bd7ec96c601c04641275ae33cd42dc0/Dienstleistung.svg"
    },
    "anchorName": "Dienstleistung"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='39cZL8zFReAGye8SKOESue-4wTdVav3hYC4uWcYMqKqoY'
          id='Vorteile'
        >
          <Highlights
            {...{
    "type": "highlights",
    "id": "4wTdVav3hYC4uWcYMqKqoY",
    "updatedAt": "2018-04-05T06:44:34.638Z",
    "title": "Das zeichnet uns aus",
    "description": "<p>Als spezialisierter Wärmepumpen-Profi wissen wir, worauf es ankommt. Nutzen Sie unsere Erfahrung!</p>\n",
    "highlights": [
        {
            "type": "highlight",
            "id": "ktnzC7nGLu6U6yAqEO482",
            "updatedAt": "2018-04-02T09:26:34.589Z",
            "title": "Volle Transparenz",
            "description": "Es fängt schon gut an: Sie erhalten von uns eine klare Offerte mit Komplettpreis und ohne Kleingedrucktes. Alles inbegriffen. Und danach halten wir Sie über jeden Projektfortschritt auf dem Laufenden.",
            "image": {
                "description": "Heizungsmacher Icon ehrlicher und transparenter Preis Heizungsofferte",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3nqVjAMcicO2sk688oaWuA/14ca28fbf5390768a93f8c4c03ffd7be/volle_transparenz_w.svg"
            },
            "link": {
                "type": "link",
                "id": "17720sg2CsMgicWQGG8I4S",
                "updatedAt": "2019-03-20T12:25:20.538Z",
                "label": "Mehr erfahren",
                "page": {
                    "slug": "projektplanung"
                }
            }
        },
        {
            "type": "highlight",
            "id": "YBiANuL0GcEIgU60i206w",
            "updatedAt": "2018-04-02T09:26:37.731Z",
            "title": "Effiziente Prozesse",
            "description": "Wir übernehmen die Verantwortung für den kompletten Einbau Ihrer Wärmepumpe. Sie haben einen einzigen Ansprechpartner und profitieren von der effizienten Auftragsabwicklung.",
            "image": {
                "description": "Heizungsmacher Icon effiziente Prozesse aus einer Hand",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2BtMtnSDKcm0QYsQ6YiyK2/bec15b54ecbd2c752fce73f077c3589a/effiziente_prozesse_w.svg"
            },
            "link": {
                "type": "link",
                "id": "5WIbPTNfpYEGg4me6iImOS",
                "updatedAt": "2018-05-24T09:11:15.059Z",
                "label": "Mehr erfahren",
                "page": {
                    "slug": "team"
                }
            }
        },
        {
            "type": "highlight",
            "id": "6bTzTjF2nu0COiOuEGaQy6",
            "updatedAt": "2018-04-02T09:26:40.455Z",
            "title": "Zuverlässige Technik",
            "description": "Wir verwenden nur Markengeräte mit Qualitätssiegel und stellen ein System zusammen, das perfekt zu Ihrem Haus und Ihren Bedürfnissen passt.",
            "image": {
                "description": "Heizungsmacher Icon Wärmepumpentechnik",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1VS1jtcHnaMksyyMMQOUOK/4e60dc89bc5cd08d853235fe4826abc3/zuverl__ssige_technik_w.svg"
            },
            "link": {
                "type": "link",
                "id": "5iXOO08O1ao2uMKYmyOUQ0",
                "updatedAt": "2018-05-24T09:11:25.831Z",
                "label": "Mehr erfahren",
                "page": {
                    "slug": "wissen-alt"
                }
            }
        },
        {
            "type": "highlight",
            "id": "43M0U9BFr2E2UwUcaam86g",
            "updatedAt": "2020-07-24T09:32:36.750Z",
            "title": "Starke Partner",
            "description": "Unsere regionalen Installationspartner wählen wir sorgfältig aus. Wir sorgen dafür, dass unsere erfahrenen Leute die Arbeiten bei Ihnen einwandfrei und rasch erledigen.",
            "image": {
                "description": "Heizungsmacher Icon lokale Partner",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1pW610S6lKoiW8KaO4ySuO/ff2de2f3575466e9a4ea806c42392883/starke_partner_w.svg"
            },
            "link": {
                "type": "link",
                "id": "47OweQapcQqQuaeSOk2Mai",
                "updatedAt": "2018-05-24T09:11:36.286Z",
                "label": "Mehr erfahren",
                "page": {
                    "slug": "team"
                }
            }
        }
    ],
    "anchorName": "Vorteile"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='39cZL8zFReAGye8SKOESue-6kHJHtM43CUqGWCemiuIkY'
          id='System'
        >
          <Knowledge
            {...{
    "type": "knowledge",
    "id": "6kHJHtM43CUqGWCemiuIkY",
    "updatedAt": "2023-07-25T12:49:14.199Z",
    "image": {
        "description": "Einfamilienhaus mit Sicht auf die Haustechnik im Speziellen die Wärmepumpe und der Kombispeicher",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/27lpeHDFoAwwSaiOEUsGaA/a540d57baf926a41ea05153ad333f916/hardik-pandya-294216_highlight.png"
    },
    "details": [
        {
            "type": "knowledgeDetail",
            "id": "WhBuXcoUmIeMKY6gUQEuK",
            "updatedAt": "2018-04-02T08:48:13.570Z",
            "description": "<p><strong>Wärmeverteilung</strong>\nOb Radiatoren oder Bodenheizung – wir legen die Wärmepumpe so aus, wie es zu den bestehenden Gebäudeinstallationen passt.</p>\n"
        },
        {
            "type": "knowledgeDetail",
            "id": "2lndIaRVRe0Ue0OM6GoIki",
            "updatedAt": "2018-04-03T12:40:39.598Z",
            "description": "<p><strong>Warmwasserspeicher</strong>\nDamit Sie immer genauso warm duschen können wie Sie möchten, berechnen wir den Warmwasserspeicher exakt nach Ihren Bedürfnissen.</p>\n"
        },
        {
            "type": "knowledgeDetail",
            "id": "5ouSgYT9726Miyooe0gAQu",
            "updatedAt": "2018-04-02T08:49:30.926Z",
            "description": "<p><strong>Wärmepumpe</strong>\nWir sorgen dafür, dass das Herz Ihrer neuen Heizung genau richtig dimensioniert ist. So geniessen Sie immer angenehme Raumtemperaturen, ohne zu viel zu bezahlen.</p>\n"
        },
        {
            "type": "knowledgeDetail",
            "id": "3OoyGAPGYMkuWeGSss4ycu",
            "updatedAt": "2022-05-10T07:42:31.730Z",
            "description": "<p><strong>Kombi-Lösung: <a href=\"https://www.heizungsmacher.ch/waermepumpe-photovoltaik/\">Wärmepumpe &#x26; Photovoltaik</a></strong>\nAls Heizungs- und Solarprofis besitzen wir das Know-how für die einwandfreie Einbindung von Wärmepumpen in Photovoltaik-Anlagen in Ein- und Zweifamilienhäusern.</p>\n"
        }
    ],
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    },
    "imageInBackground": true,
    "anchorName": "System",
    "description": ""
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='39cZL8zFReAGye8SKOESue-1jSmqMTCXiS0U8mo2Coei0'
          id='Service'
        >
          <Knowledge
            {...{
    "type": "knowledge",
    "id": "1jSmqMTCXiS0U8mo2Coei0",
    "updatedAt": "2019-03-14T16:06:28.744Z",
    "title": "Sie läuft und läuft und läuft",
    "description": "<p>Wärmepumpen sind äusserst wartungsarm und zuverlässig. Doch auch im Falle eines Falles sind wir jederzeit für Sie da. Alle von uns installieren Wärmepumpen haben ein Notprogramm, für den Fall der Fälle.</p>\n",
    "image": {
        "description": "Heizungsmacher Firmenauto VW Teamfoto",
        "contentType": "image/jpeg",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/2lb44FlF9msqCAS2quKysY/45b89dd03d2842456259e225c9262cbc/Foto-f__r---wir-leben-was-wir-sagen.jpg"
    },
    "details": [
        {
            "type": "knowledgeDetail",
            "id": "65AF9rsbuMok46qIy4kyqS",
            "updatedAt": "2018-04-02T08:58:02.590Z",
            "description": "<p>Jeder Hersteller hat unterschiedliche Garantie-Bedinungen und Möglichkeiten. Wir empfehlen ihnen was sinnvoll ist und was nicht.</p>\n"
        },
        {
            "type": "knowledgeDetail",
            "id": "4HV03Jd2asMKMMwGi44KOC",
            "updatedAt": "2018-04-02T09:02:25.389Z",
            "description": "<p>Wir reden nicht nur darüber, wir handeln. Sollte an ihrer Anlage trotzdem ein Problem auftauchen, sind wir für sie da.</p>\n"
        },
        {
            "type": "knowledgeDetail",
            "id": "mnVyecZqIo206S8O6WYyK",
            "updatedAt": "2018-04-02T09:01:16.866Z",
            "description": "<p>Wir gehen mit gutem Beispiel voran. Unsere Mitarbeiter fahren nur mit Elektroautos.</p>\n"
        },
        {
            "type": "knowledgeDetail",
            "id": "7xjBbsNMnCgEUcAWMs04s2",
            "updatedAt": "2018-04-03T12:35:03.310Z",
            "description": "<p>Moderne Wärmepumpen sind am Internet angeschlossen. Damit lassen sich Fehler schnell erkennen und allfällige Korrekturen ferngesteuert vornehmen.</p>\n"
        }
    ],
    "link": {
        "type": "link",
        "id": "52LtUnYpq3pbMjNzaKpmjM",
        "updatedAt": "2019-05-03T15:34:26.732Z",
        "label": "Kontaktieren Sie uns",
        "url": "/kontakt"
    },
    "imageInBackground": false,
    "anchorName": "Service"
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
